import { useState, useEffect } from "react";
import './App.css';

let dev = false
let url = dev ? 'localhost:5000' : 'news-agg.backend.news-agg.a2hosted.com'
let http = dev ? 'http' : 'https'
function App() {
  // let [data, setData] = useState(getRels())
  let [selected, setSelected] = useState({})
  let [lastId, setLastId] = useState('')
  let [bufferCutOff, setBufferCutOff] = useState('')
  let [amountOfRels, setAmountOfRels] = useState('')
  let [firstGet, setFirstGet] = useState(false)
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!firstGet) {
      get()
      setFirstGet(true)
    }
  }, [selected, lastId, bufferCutOff, amountOfRels, firstGet, loading])

  let get = () => {
    setLoading(true)
    fetch(`${http}://${url}/api/data/labeled/relationships/random`).then(res => res.json()).then(res => {
      setSelected(res['payload']);
      setBufferCutOff(parseInt(res['bufferCutOff']));
      setAmountOfRels(parseInt(res['amount_of_rels']))
      setLoading(false)
    });
  }

  let post = (url_1, url_2, action) => {
    fetch(`${http}://${url}/api/data/labeled/relationship/post`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization": `*`,
        "Content-Type": "application/json"
      }, body: JSON.stringify({
        'url_1': url_1,
        'url_2': url_2,
        'action': action
      })
    }).then(res => res).then(res => {
      if (res.status !== 200) alert('Connection Error! Please Contact the Man Himself!')
      return res.json()
    }).then(res => {
      setLastId(res['id']);
    })
  }

  let clickAction = (action) => {
    post(selected['prime']['url'], selected['sorted_buffer'][0]['url'], action)
    selected['sorted_buffer'] = selected['sorted_buffer'].slice(1)
    if (selected['sorted_buffer'].length === 0 || amountOfRels >= bufferCutOff) get();
    else setAmountOfRels(amountOfRels + 1)
  }

  let undo = () => {
    fetch(`${http}://${url}/api/data/labeled/relationship/delete`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization": `*`,
        "Content-Type": "application/json"
      }, body: JSON.stringify({ 'id': lastId })
    }).then(res => res).then(res => {
      if (res.status !== 200) alert('Connection Error! Please Contact the Man Himself!')
      return res.json();
    }).then(res => {
      setLastId('');
    })
    get()
  }

  return (
    <div className="App">
      {loading && <div class="padding">loading ...</div>}

      {'prime' in selected && !loading && <div className="display">
        <div>
          <div>{selected['prime']['headline']}</div>
          <a href={selected['prime']['url']}>{selected['prime']['url']}</a>
        </div>
        <div>
          <div>{selected['sorted_buffer'][0]['headline']}</div>
          <a href={selected['sorted_buffer'][0]['url']}>{selected['sorted_buffer'][0]['url']}</a>
        </div>
      </div>}

      <div className="inline-buttons">
        <button onClick={() => clickAction('Same')} disabled={loading}>Same</button>
        <button onClick={() => clickAction('Similar')} disabled={loading}>Similar</button>
        <button onClick={() => clickAction('Different')} disabled={loading}>Different</button>
        {lastId.length !== 0 && <button onClick={() => undo()} disabled={loading}>Undo</button>}
        {/* <button onClick={get}>skip</button> */}
        <div class='paddingtop' disabled={loading}>Amount until next: {bufferCutOff - amountOfRels}</div>
      </div>
    </div>
  );
}

export default App;
